/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import "~jsoneditor/dist/jsoneditor.min.css";

body {
  font-family: "Poppins", sans-serif !important;
}

.ant-layout {
  background-color: var(--modal-border-color);
}

.ant-pagination-item {
  color: var(--secondary-color)
}
.ant-pagination-item-active a {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  &:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }

}

.ant-table-thead > .ant-table-row > .ant-table-cell{
  background-color: transparent;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #000001;
  border-size: 50%;
}

.ant-table-tbody > .ant-table-row > .ant-table-cell{
  background-color: transparent;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #000001;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: transparent;
}

.ant-table-thead > .ant-table-row:first-child > .ant-table-cell{
  background-color: transparent;
  border-bottom-width: medium;
}

.ant-table-tbody > .ant-table-row:last-child > .ant-table-cell {
  border-bottom-color: transparent;
}

.ant-table-tbody > tr:last-child > td {
  border-bottom-color: transparent;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent
}

.ant-modal-content {
  border-radius: 10px;
  max-width: 600px;
  margin: auto;
}

.ant-modal-header {
  border-radius: 10px;
}

.ant-btn {
  height: 35px;
  border-radius: 4px;
  background: #DEE2E6;
}

.ant-btn-link {
  background: transparent
}

.ant-input {
  border-radius: 4px;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  background: rgba(3, 54, 102, 0.49);
}

.ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus, .ant-btn-primary:active {
  background:  #033666;
  border-color:  #033666;
}

.ant-card-body {
  border-radius: 30px;
  background-color: white;
}

.ant-card-body-fit {
  width: -moz-fit-content;
  min-inline-size: fit-content;
}

.ant-card-small > .ant-card-head  {
  margin-bottom: 0;
}

.ant-card-head  {
  margin-bottom: 0;
}


.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon {
  color: #C9565D;
}

.ant-btn-dangerous.ant-btn-primary {
  background-color: #E9CACA;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.85);
}

.ant-btn-dangerous.ant-btn-primary:hover {
  background-color: #C9565D;
  border-color: #C9565D;
}

.ant-popover-inner {
  border-radius: 10px;
  }
