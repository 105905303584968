.gridster {
    background: transparent;
}

.gridster-column,
.gridster-row {
    border-color: rgba(0, 0, 0, 0.05);
}

gridster-preview {
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.1);
}

gridster-item {
    border-radius: 6px;
    border: 1px solid #dfdfdf;

    &.gridster-item-moving,
    &.gridster-item-resizing {
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.02), 0 8px 15px 0 rgba(0, 0, 0, 0.05), 0 1px 30px 0 rgba(0, 0, 0, 0.05);
    }
}

.gridster-column,
.gridster-row {
    border-color: rgba(0, 0, 0, 0.05);
}

gridster-preview {
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.1);
}

gridster-item {
    border-radius: 6px;
    border: 1px solid #dfdfdf;

    &.gridster-item-moving,
    &.gridster-item-resizing {
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.02), 0 8px 15px 0 rgba(0, 0, 0, 0.05), 0 1px 30px 0 rgba(0, 0, 0, 0.05);
    }
}

gridster-item > * {
    pointer-events: initial;
}
.gridster-item-moving app-widget {
    pointer-events: none;
}
.gridster-item-resizing app-widget {
    pointer-events: none;
}

.pg-title {
    font-size: 28px;
    margin-bottom: 10px;
}

.loading-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
}

.pg-link {
    transition: color 0.3s;
    color: var(--secondary-color);
    &:hover {
        color: #bfbfbfa8 !important;
    }
}

.ant-checkbox-checked .ant-checkbox-inner::after{
    border-color: white !important;
}
